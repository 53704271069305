
        let gqlDocs = [{"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Me"},"variableDefinitions":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"profile"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":121}}];
        let attrs = {"noAnonymousOperations":false,"vue":true,"type":"custom","index":"0","blockType":"graphql"} || {};
        let handler = require("D:\\a\\1\\s\\YellowDuck.FE\\node_modules\\vue-graphql-loader\\lib\\handler");

        if (handler.default) {
          handler = handler.default;
        }
        module.exports = function vueGraphqlLoader(component) {
          handler(component, gqlDocs, attrs);
        }